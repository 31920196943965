import { combineReducers } from "redux";
import { UserReducer } from "./UserReducer";
import { ClientReducer } from "./ClientReducer"
import { VehicleReducer } from "./VehicleReducer";
import { MasterTypeReducer } from "./MasterTypeReducer";
import { MasterDataReducer } from "./MasterDataReducer";
import { OperationalMasterReducer } from "./OperationalMasterReducer";
import { VehicleManufactureReducer } from "./mst_reducer/VehicleManufactureReducer";
import { VehicleUsageReducer } from "./mst_reducer/VehicleUsageReducer";
import { VehicleTireMakeReducer } from "./mst_reducer/VehicleTireMakeReducer";
import { VehicleTypeReducer } from "./mst_reducer/VehicleTypeReducer";
import { VehicleSubTypeReducer } from "./mst_reducer/VehicleSubTypeReducer";
import { VehicleModelReducer } from "./mst_reducer/VehicleModelReducer";
import { ObservationCategoryMasterReducer } from "./ObservationCategoryMasterReducer";
import { TireConfigurationMasterReducer } from "./TireConfigurationMasterReducer";
import { InspectionReducer } from "./InspectionReducer";

const rootReducer = combineReducers({
    authUser: UserReducer,
    client: ClientReducer,
    vehicle: VehicleReducer,
    masterType: MasterTypeReducer,
    masterData: MasterDataReducer,
    operational: OperationalMasterReducer,
    vehicleManufacture:VehicleManufactureReducer,
    vehicleUsage:VehicleUsageReducer ,
    vehicleTireMake:VehicleTireMakeReducer,
    vehicleType: VehicleTypeReducer,
    vehicleSubType: VehicleSubTypeReducer,
    vehicleModel: VehicleModelReducer,
    observationCategoryMaster: ObservationCategoryMasterReducer,
    tireConfigurationMaster: TireConfigurationMasterReducer,
    inspection: InspectionReducer
})

export default rootReducer;