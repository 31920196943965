import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserMethods from '../../../asyncMethods/UserMethods';
import UserActionButtons from '../../../components/ActionButtons/UserActionButtons';
import FilterComponent from '../../../components/FilterComponent/Index';

const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
  },
  {
    name: "Email",
    selector: (row) => row.emailId,
  },
  {
    name: "Mobile",
    selector: (row) => row.mobileNumber,
  },
  {
    name: "Role",
    selector: (row) => row.roleType,
  },
  {
    name: "Status",
    selector: (row) => row.activeStatus,
    sortable: true
  },
  {
    name: "Actions",
    selector: (row) => row.actionButtons,
    center: true
  }
];

const Users = () => {

  const userMethods = UserMethods();

  const navigate = useNavigate()

  useEffect(() => {
    userMethods.fetchAllUsers();
  }, []);

  const statusUpdate = (userId, status) => {

    const data = {
      id: userId,
      status: status == 1 ? 0 : 1
    }
    userMethods.userStatusUpdate(data);
  }

  const data = Array.from(useSelector((state) => state.authUser.userList))
    .map((user) => ({
      ...user,
      actionButtons: (
        <UserActionButtons onStatusUpdate={statusUpdate} userDetails={user} />
      ),
    }))
    .sort((b, a) => a.userId - b.userId);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = data.filter(
    (item) =>
      (item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      item.mobileNumber.toString().toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <h5></h5>
        <Button onClick={() => navigate("/users/create")} variant="primary">
          <i className="fa-solid fa-plus"></i> New User
        </Button>
      </div>
      <Card>
        <Card.Body>
          <DataTable
            title="User List"
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default Users;