import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Moment from 'react-moment';

import ReportDetailsActionButtons from "../../../../components/ActionButtons/ReportDetailsActionButtons";
import FilterComponent from "../../../../components/FilterComponent/Index";
import InspectionMethods from "../../../../asyncMethods/InspectionMethods";

const columns = [
  {
    name: "Inspection ID",
    selector: (row) => row.inspectionId,
    sortable: true
  },
  {
    name: "Tire Position",
    selector: (row) => row.tirePosition
  },
  {
    name: "New / Retreated",
    selector: (row) => row?.tireTypeLabel,
    center: true
  },
  {
    name: "Tire Make",
    selector: (row) => row?.tireMakeLabel,
    center: true
  },
  {
    name: "Tire Size",
    selector: (row) => row?.tireSize,
    center: true
  },
  {
    name: "Tire Pattern",
    selector: (row) => row?.tirePattern,
    center: true
  },
  {
    name: "Tire Sr. No",
    selector: (row) => row?.tireSerialNo,
    center: true
  },
  {
    name: "OTD mm",
    selector: (row) => row?.otdMm,
    center: true
  },
  {
    name: "RST mm",
    selector: (row) => row?.rstMm,
    center: true
  },
  {
    name: "CT mm",
    selector: (row) => row?.ctMm,
    center: true
  },
  {
    name: "LST mm",
    selector: (row) => row?.lstMm,
    center: true
  },
  {
    name: "RTD",
    selector: (row) => row?.rtd,
    center: true
  },
  {
    name: "Reco. Pressure",
    selector: (row) => row?.recoPressure,
    center: true
  },
  {
    name: "Obs. Pressure",
    selector: (row) => row?.obsPressure,
    center: true
  },
  {
    name: "Observation Category",
    selector: (row) => row?.observationCategoryLabel,
    center: true
  },
  {
    name: "Observation",
    selector: (row) => row?.observationLabel,
    center: true
  },
  {
    name: "Recommendation",
    selector: (row) => row?.recommendationLabel,
    center: true
  },
  {
    name: "Original Fitment Date",
    selector: (row) => <Moment format="MM/DD/YYYY">{row?.tireOriginalFitmentDate}</Moment>,
    center: true
  },
  {
    name: "Odometer Reading when fitted",
    selector: (row) => row?.odometerReadingWhenFitted,
    center: true
  },
  {
    name: "Mileage per mm",
    selector: (row) => row?.mileagePerMm,
    center: true
  },
  {
    name: "Projected Life",
    selector: (row) => row?.projectedMileage,
    center: true
  },
  {
    name: "Least Tire Thickness Allowed mm",
    selector: (row) => row?.leastTireThicknessAllowed,
    center: true
  },
  {
    name: "Tire Removal Date",
    selector: (row) => <Moment format="MM/DD/YYYY">{row?.tireRemovalDate}</Moment>,
    center: true
  },
  {
    name: "Odometer Reading when removed",
    selector: (row) => row?.odometerReadingWhenRemoved,
    center: true
  },
  {
    name: "Tire Image Url",
    selector: (row) => row?.tireImageUrl,
    center: true
  },
  {
    name: "Action",
    selector: (row) => row?.actionButtons,
    center: true
  },
]

const DetailsReport = () => {
  const navigate = useNavigate();
  const parameters = useParams();
  const [filterText, setFilterText] = useState("");
  const inspectionMethods = InspectionMethods();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    if (parameters.inspectionId) {
        inspectionMethods.getVehicleInspectionDetails(parameters.inspectionId);
    }
  }, []);


  const data = Array.from(useSelector((state) => state?.inspection.inspectionDetails))
    .map((inspection) => ({
      ...inspection,
      actionButtons: (
        <ReportDetailsActionButtons
            reportDetails={inspection}
        />
      ),
    }))
    .sort((b, a) => a.inspectionId - b.inspectionId);

//   let filteredItems = data.filter(
//     (item) =>
//       ((item.inspectionId || "").toString().toLowerCase().includes(filterText.toString().toLowerCase()) ||
//       (item.clientName || "").toString().toLowerCase().includes(filterText.toString().toLowerCase()) ||
//       (item.vehicleRegNumber || "").toString().toLowerCase().includes(filterText.toString().toLowerCase()))
//   );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);


  return (
    <>
      <Card>
        <Card.Body>
          <DataTable
            title="Details Report"
            columns={columns}
            data={data}
            paginationResetDefaultPage={resetPaginationToggle}
            pagination
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default DetailsReport;
