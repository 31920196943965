import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import TextInputField from "../../../components/InputField/TextInputField";
import UserMethods from "../../../asyncMethods/UserMethods";

const schema = yup
  .object({
    username: yup
      .mixed()
      .test(
        "username",
        "Please enter a valid email or mobile",
        function (value, context) {
          if (value === "") return false;
          if (
            yup.string().length(10).matches(/^\d+$/).isValidSync(value) ||
            yup.string().email().isValidSync(value)
          ) {
            return true;
          }
          return false;
        }
      )
      .required(),
  })
  .required();

const CreateUser = () => {
  const navigate = useNavigate();
  const userMethods = UserMethods();

  const [initialValues, setInitialValues] = useState({
    username: ""
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(formik) => {
        const { values, errors, touched, isValid, dirty } = formik;
        return (
          <>
            <div className="d-flex justify-content-between ml-3">
              <h4>{"Forgot Password"}</h4>
            </div>
            <Form>
              <div className="m-3">
                <div className="form-group">
                  <TextInputField
                    errors={errors}
                    touched={touched}
                    type={"text"}
                    label={"Email or Number "}
                    name={"username"}
                    required={true}
                  />
                </div>
                <div className="form-group">
                  <p className="small text-right">
                    <Link to="/">Login</Link>
                  </p>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn-fill btn btn-info">
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateUser;
