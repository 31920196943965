import React, { useState, useRef, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import TextInputField from "../../../components/InputField/TextInputField";
import Select from "../../../components/DropdownField/Select";
import VehicleManufactureDataMethod from "../../../asyncMethods/mst_action/VehicleManufactureDataMethod";
import VehicleTypeDataMethod from "../../../asyncMethods/mst_action/VehicleTypeDataMethod";
import VehicleModelDataMethod from "../../../asyncMethods/mst_action/VehicleModelDataMethod";
import ClientMethods from "../../../asyncMethods/ClientMethods";
import InspectionMethods from "../../../asyncMethods/InspectionMethods";

const InspectionForm = () => {
  const parameters = useParams();
  const formRef = useRef();
  const schema = Yup.object({
    clientId: Yup.string().required("Client Name is required !"),
    clientInformation: Yup.string().optional(),
    clientName: Yup.string().optional(),
    clientServiceLocationId: Yup.string().required(
      "Client Location is required."
    ),
    inspectionDateTime: Yup.string().required("Date And Time is required !"),
    vehicleRegNumber: Yup.string().required("Vehicle Reg No is required !"),
    vehicleManufacturer: Yup.string().optional(),
    vehicleManufacturerLabel: Yup.string().optional(),
    vehicleModel: Yup.string().optional(),
    vehicleType: Yup.string().optional(),
    vehicleOdometerReading: Yup.string().required(
      "Vehicle Odometer Reading is required !"
    ),
    vehicleId: Yup.string().required("Vehicle Id is required !"),
    noOfTires: Yup.string().optional(),
    configuredVehicle: Yup.string().required(
      "vehicleConfigurationKey is required !"
    ),
    noOfStepneys: Yup.string().optional(),
    inspectionStatus: Yup.string().optional(),
    tireSequence: Yup.string().optional(),
  });

  const [initialValues, setInitialValues] = useState({
    inspectionId: "",
    clientId: "",
    clientName: "",
    clientInformation: "",
    clientServiceLocationId: "",
    inspectionDateTime: "",
    vehicleRegNumber: "",
    vehicleOdometerReading: "",
    vehicleId: "",
    noOfTires: "",
    configuredVehicle: "",
    noOfStepneys: "",
    inspectionStatus: "1",
    vehicleManufacturer: "",
    vehicleManufacturerLabel: "",
    vehicleModel: "",
    vehicleType: "",
    tireSequence: "",
  });

  const [selectedClient, setSelectedClient] = useState([]);
  const [serviceLocations, setServiceLocations] = useState([]);

  const vehicleManufactureDataMethod = VehicleManufactureDataMethod();
  const vehicleTypeDataMethod = VehicleTypeDataMethod();
  const vehicleModelDataMethod = VehicleModelDataMethod();
  const clientMethods = ClientMethods();
  const inspectionMethods = InspectionMethods();

  const vehicleManufactureOptions = Array.from(
    useSelector((state) => state?.vehicleManufacture?.vehicleManufactureList)
  ).filter((s) => s.activeStatus == 1);
  const vehicleTypeOptions = Array.from(
    useSelector((state) => state?.vehicleType?.vehicleTypeList)
  ).filter((s) => s.activeStatus == 1);
  const vehicleModelOptions = Array.from(
    useSelector((state) => state?.vehicleModel?.filterVehicleModelList)
  ).filter((s) => s.activeStatus == 1);
  const clients = Array.from(useSelector((state) => state?.client?.clientList));
  const clientLocationArr = Array.from(
    useSelector((state) => state?.client?.clientList)
  );

  useEffect(() => {
    vehicleManufactureDataMethod.fetchAllVehicleManufactureData();
    vehicleTypeDataMethod.fetchAllVehicleTypeData();
    vehicleModelDataMethod.fetchAllVehicleModelData();
    clientMethods.fetchAllClients();

    if (parameters.inspectionId) {
      inspectionMethods.fetchInspection(parameters.inspectionId);
    }
  }, []);

  const editInspectionData = useSelector(
    (state) => state.inspection.editInspection
  );

  useEffect(() => {
    if (
      parameters.inspectionId &&
      editInspectionData.inspectionId == parameters.inspectionId &&
      clients &&
      clients.length > 0
    ) {
      if (editInspectionData?.inspectionDateTime) {
        editInspectionData.inspectionDateTime =
          editInspectionData.inspectionDateTime.toString().slice(0, 16);
      }
      const selectedClient = clients?.find(
        (c) => c.clientId == editInspectionData.clientId
      );
      if (selectedClient) {
        setSelectedClient([selectedClient]);
        setServiceLocations(selectedClient.serviceLocations);
      }
      editInspectionData.clientServiceLocationId = (
        editInspectionData.clientServiceLocationId || ""
      ).toString();
      vehicleManufactureDataMethod.getVehicleByFilter(
        editInspectionData.vehicleManufacturer
      );
      setInitialValues(editInspectionData);
    }
  }, [editInspectionData]);

  const onSubmit = (data) => {
    if (parameters.inspectionId) {
      inspectionMethods.updateInspection(parameters.inspectionId, data);
    } else {
      inspectionMethods.storeInspection(data);
    }
  };

  const onSelectVehicle = async (data, setFieldValue, type) => {
    if (data.vehicleManufacturer && data.vehicleModel && data.vehicleType) {
      const response = await inspectionMethods.getVehicleByFilter(
        data.vehicleManufacturer,
        data.vehicleModel,
        data.vehicleType
      );
      setFieldValue("vehicleId", response ? response.vehicleId : "");
      setFieldValue(
        "configuredVehicle",
        response ? response.configuredVehicle : ""
      );
      setFieldValue("noOfTires", response ? response.noOfTires : "");
      setFieldValue("noOfStepneys", response ? response.noOfStepneys : "");
      setFieldValue("tireSequence", response ? response.tireSequence : "");
    }

    if (type === "vehicleManufacturer") {
      const selectedManufacture = vehicleManufactureOptions.find(
        (v) => v.vehicleManufacturerId == data.vehicleManufacturer
      );
      setFieldValue(
        "vehicleManufacturerLabel",
        selectedManufacture.vehicleManufacturerName
      );
      vehicleManufactureDataMethod.getVehicleByFilter(data.vehicleManufacturer);
    }

    if (type === "vehicleModel") {
      const selectedModel = vehicleModelOptions.find(
        (v) => v.vehicleModelId == data.vehicleModel
      );
      setFieldValue("vehicleModelLabel", selectedModel.vehicleModelName);
    }

    if (type === "vehicleType") {
      const selectedType = vehicleTypeOptions.find(
        (v) => v.vehicleTypeId == data.vehicleType
      );
      console.log(
        "🚀 ~ file: InspectionForm.js:127 ~ onSelectVehicle ~ selectedType:",
        selectedType
      );
      setFieldValue("vehicleTypeLabel", selectedType.vehicleTypeName);
    }

    if (type === "vehicleType") {
      const selectedType = vehicleTypeOptions.find(
        (v) => v.vehicleTypeId == data.vehicleType
      );
      console.log(
        "🚀 ~ file: InspectionForm.js:127 ~ onSelectVehicle ~ selectedType:",
        selectedType
      );
      setFieldValue("vehicleTypeLabel", selectedType.vehicleTypeName);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={(values) => {
          console.log("----------", values);
          onSubmit(values);
        }}
      >
        {(formik) => {
          const { errors, touched, setFieldValue, setFieldTouched, values } =
            formik;
          return (
            <>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <Form>
                      {parameters?.inspectionId && (
                        <div className="row mb-4">
                          <div className="col-6">Inspection ID:</div>
                          <div className="col-6 text-muted h-40">
                            {values?.inspectionId ? values?.inspectionId : "-"}
                          </div>
                        </div>
                      )}

                      <div className="row mb-4">
                        <div className="col-6">
                          Client Name : {selectedClient.clientId}
                        </div>
                        <div className="col-6 text-muted">
                          <Typeahead
                            clearButton
                            id="basic-typeahead-single"
                            multiple={false}
                            disabled={parameters.inspectionId}
                            onChange={(selected) => {
                              const value =
                                selected.length > 0 ? selected[0].clientId : "";
                              setFieldValue("clientId", value);
                              setFieldValue(
                                "clientName",
                                value ? selected[0].clientFullName : ""
                              );
                              setSelectedClient(selected);
                              if (value) {
                                setFieldValue(
                                  "clientInformation",
                                  `${selected[0].clientCompanyName},${selected[0].clientMobileNo}`
                                );
                                setServiceLocations(
                                  selected[0].serviceLocations
                                );
                              } else {
                                setFieldValue("clientInformation", "");
                                setServiceLocations([]);
                              }
                            }}
                            onBlur={(e) => setFieldTouched("clientId", true)}
                            labelKey="clientFullName"
                            options={clients}
                            selected={selectedClient}
                          />
                          <div className="text-danger">
                            {touched.clientId && errors.clientId}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Client Information :</div>
                        {!values.clientInformation && (
                          <div className="col-6 text-muted h-40 pl-4">
                            Auto Fill
                          </div>
                        )}
                        {values.clientInformation && (
                          <div className="col-6 text-muted h-40">
                            {values.clientInformation}
                          </div>
                        )}
                      </div>

                      {values.clientInformation && (
                        <div className="row mb-4">
                          <div className="col-6">Client Location :</div>
                          <div className="col-6 text-muted">
                            <Select
                              errors={errors}
                              touched={touched}
                              label={false}
                              readOnly={parameters.inspectionId}
                              name="clientServiceLocationId"
                              required={false}
                              onChange={(e) => {
                                setFieldValue(
                                  "clientServiceLocationId",
                                  e.target.value
                                );
                                const selectedAddress = serviceLocations.find(
                                  (s) =>
                                    s.clientServiceLocationId ===
                                    Number(e.target.value)
                                );
                                if (selectedAddress) {
                                  setFieldValue(
                                    "clientServiceLocationAddress",
                                    `${selectedAddress.clientServiceAddress}, ${selectedAddress.clientServiceCity}, ${selectedAddress.clientServicePincode}`
                                  );
                                  values.clientServiceLocationAddress = `${selectedAddress.clientServiceAddress}, ${selectedAddress.clientServiceCity}, ${selectedAddress.clientServicePincode}`;
                                }
                                values.clientServiceLocationId = e.target.value;
                              }}
                            >
                              <option value="">select</option>
                              {serviceLocations?.map((data) => (
                                <option
                                  key={data.clientServiceLocationId}
                                  value={data.clientServiceLocationId}
                                >
                                  {`${data.clientServiceAddress}, ${data.clientServiceCity}, ${data.clientServicePincode}`}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      )}

                      <div className="row mb-4">
                        <div className="col-6">Date and Time :</div>
                        <div className="col-6 text-muted">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            type={"datetime-local"}
                            label={false}
                            name={"inspectionDateTime"}
                            readOnly={parameters.inspectionId}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Vehicle Reg No :</div>
                        <div className="col-6 text-muted">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            type={"text"}
                            label={false}
                            name={"vehicleRegNumber"}
                            readOnly={parameters.inspectionId}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">
                          Vehicle Manufacturer (Master) :
                        </div>
                        <div className="col-6 text-muted">
                          <Select
                            errors={errors}
                            touched={touched}
                            label={false}
                            readOnly={parameters.inspectionId}
                            name="vehicleManufacturer"
                            value={values.vehicleManufacturer}
                            required={false}
                            onChange={(e) => {
                              setFieldValue(
                                "vehicleManufacturer",
                                e.target.value
                              );
                              values.vehicleManufacturer = e.target.value;
                              onSelectVehicle(
                                values,
                                setFieldValue,
                                "vehicleManufacturer"
                              );
                            }}
                          >
                            <option value="">select</option>

                            {vehicleManufactureOptions?.map((data, index) => (
                              <option
                                key={data.vehicleManufacturerId}
                                value={data.vehicleManufacturerId}
                              >
                                {data.vehicleManufacturerName}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Vehicle Model :</div>
                        <div className="col-6 text-muted">
                          <Select
                            errors={errors}
                            touched={touched}
                            label={false}
                            readOnly={parameters.inspectionId}
                            name="vehicleModel"
                            required={false}
                            onChange={(e) => {
                              setFieldValue("vehicleModel", e.target.value);
                              values.vehicleModel = e.target.value;
                              onSelectVehicle(
                                values,
                                setFieldValue,
                                "vehicleModel"
                              );
                            }}
                          >
                            <option value="">select</option>

                            {vehicleModelOptions?.map((data, index) => (
                              <option
                                key={data.vehicleModelId}
                                value={data.vehicleModelId}
                                data-dataid={data.vehicleModelId}
                              >
                                {data.vehicleModelName}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Vehicle type :</div>
                        <div className="col-6 text-muted">
                          <Select
                            errors={errors}
                            touched={touched}
                            label={false}
                            readOnly={parameters.inspectionId}
                            name="vehicleType"
                            required={false}
                            onChange={(e) => {
                              setFieldValue("vehicleType", e.target.value);
                              values.vehicleType = e.target.value;
                              onSelectVehicle(
                                values,
                                setFieldValue,
                                "vehicleType"
                              );
                            }}
                          >
                            <option value="">select</option>
                            {vehicleTypeOptions?.map((data, index) => (
                              <option
                                key={data.vehicleTypeId}
                                value={data.vehicleTypeId}
                                data-dataid={data.vehicleTypeId}
                              >
                                {data.vehicleTypeName}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Vehicle Configuration Key :</div>
                        {!values.configuredVehicle && (
                          <div className="col-6 text-muted h-40 pl-4">
                            Auto Fill
                          </div>
                        )}
                        {values.configuredVehicle && (
                          <div className="col-6 text-muted h-40">
                            {values.configuredVehicle}
                          </div>
                        )}
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Odometer Reading :</div>
                        <div className="col-6 text-muted">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            type={"text"}
                            label={false}
                            name={"vehicleOdometerReading"}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-5">
                        <button
                          type="submit"
                          className="btn-fill btn btn-info m-3"
                        >
                          {parameters?.inspectionId ? "Update" : "Save"}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default InspectionForm;
