import React, { useEffect, useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import InspectionMethods from "../../../../../asyncMethods/InspectionMethods";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Moment from 'react-moment';


const DetailAnalytical = () => {

  const parameters = useParams();
  const inspectionMethods = InspectionMethods();

  useEffect(() => {
    if (parameters.inspectionId && parameters.inspectionDetailIndex) {
      inspectionMethods.getVehicleInspectionDetails(parameters.inspectionId);
    }
  }, []);

  const data = Array.from(useSelector((state) => state?.inspection.inspectionDetails))
    .find((d) => d.inspectionDetailIndex === Number(parameters.inspectionDetailIndex))

  return (
    <>
      <Card>
        <Card.Body>

          <h3>Photo Observation</h3>

          {data &&
            <div className="row">
              <div className="col-sm-5">
                <div className="d-flex justify-content-center h-100 align-items-center"><img src={`${process.env.REACT_APP_API_URL}${data?.tireImageUrl}`} height={700} /></div>
              </div>
              <div className="col-sm-7">
                <div>
                  <table cellPadding="5" className="ReportDetailsTable d-flex justify-content-center">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td>InspectionId</td>
                        <td>{data?.inspectionId}</td>
                      </tr>
                      <tr>
                        <td>Tire Position</td>
                        <td>{data?.tirePosition}</td>
                      </tr>
                      <tr>
                        <td>TireType Label</td>
                        <td>{data?.tireTypeLabel}</td>
                      </tr>
                      <tr>
                        <td>Tire Make</td>
                        <td>{data?.tireMake}</td>
                      </tr>
                      <tr>
                        <td>Tire Size</td>
                        <td>{data?.tireSize}</td>
                      </tr>
                      <tr>
                        <td>Tire Pattern</td>
                        <td>{data?.tirePattern}</td>
                      </tr>
                      <tr>
                        <td>Tire Serial No.</td>
                        <td>{data?.tireSerialNo}</td>
                      </tr>
                      <tr>
                        <td>OTD mm</td>
                        <td>{data?.otdMm}</td>
                      </tr>
                      <tr>
                        <td>RST mm</td>
                        <td>{data?.rstMm}</td>
                      </tr>
                      <tr>
                        <td>CT mm</td>
                        <td>{data?.ctMm}</td>
                      </tr>
                      <tr>
                        <td>LST mm</td>
                        <td>{data?.lstMm}</td>
                      </tr>
                      <tr>
                        <td>RTD</td>
                        <td>{data?.rtd}</td>
                      </tr>
                      <tr>
                        <td>Reco. Pressure</td>
                        <td>{data?.recoPressure}</td>
                      </tr>
                      <tr>
                        <td>Obs. Pressure</td>
                        <td>{data?.obsPressure}</td>
                      </tr>                                                                                      
                      <tr>
                        <td>Observation_Category</td>
                        <td>{data?.observationCategoryLabel}</td>
                      </tr>
                      <tr>
                        <td>Observation</td>
                        <td>{data?.observationLabel}</td>
                      </tr>
                      <tr>
                        <td>Recommendation</td>
                        <td>{data?.recommendationLabel}</td>
                      </tr>
                      <tr>
                        <td>Original Fitment Date</td>
                        <td><Moment format="MM/DD/YYYY">{data?.tireOriginalFitmentDate}</Moment></td>
                      </tr>  
                      <tr>
                        <td>Odometer Reading when fitted</td>
                        <td>{data?.odometerReadingWhenFitted}</td>
                      </tr>
                      <tr>
                        <td>Mileage Per mm</td>
                        <td>{data?.mileagePerMm}</td>
                      </tr>
                      <tr>
                        <td>Projected Life</td>
                        <td>{data?.projectedMileage}</td>
                      </tr>                                         
                      <tr>
                        <td>Least Tire Thickness Allowed</td>
                        <td>{data?.leastTireThicknessAllowed}</td>
                      </tr>                                          
                      <tr>
                        <td>Tire Removal Date</td>
                        <td><Moment format="MM/DD/YYYY">{data?.tireRemovalDate}</Moment></td>
                      </tr>
                      <tr>
                        <td>Odometer Reading when removed</td>
                        <td>{data?.odometerReadingWhenRemoved}</td>
                      </tr>                    
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>

              </div>
            </div>
          }

        </Card.Body>
      </Card>
    </>
  );
};

export default DetailAnalytical;
