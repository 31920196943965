import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const UserActionButtons = ({userDetails,onStatusUpdate}) => {

    const navigate = useNavigate();

    const redirectToEditPage = userId => {
      navigate("/users/edit/"+userDetails.userId);
    }

    return (
      <>
        <div className='d-flex align-items-center'>
          <Button variant="primary" size="sm" onClick={() => redirectToEditPage(userDetails.userId)} >
            <i className="fa-solid fa-user-pen"></i>
          </Button>
          <Form.Check
            inline
            className={'mx-3 text-danger'}
            type="switch"
            value = { userDetails.activeStatus === 1 ? 1 : 0}
            checked = { userDetails.activeStatus === 1 ? true : false}
            id={'toggle-button'+userDetails.userId}
            onChange = {(e) => {onStatusUpdate(userDetails.userId,e.target.value)}}
          />
        </div>
      </>
    );
}

export default UserActionButtons;