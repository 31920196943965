import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ReportActionButtons = ({ reportDetails }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className='d-flex align-items-center'>
                <Button
                    variant="primary" size="sm" onClick={() => navigate(`/report/analytical/${reportDetails.inspectionId}`)}>
                    Analytical
                </Button>
                <Button variant="primary" size="sm" style={{ marginLeft: '4px'}} onClick={() => navigate(`/report/detail/${reportDetails.inspectionId}`)}>
                    Detail
                </Button>
            </div>
        </>
    );
}

export default ReportActionButtons;