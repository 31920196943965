import React from "react";
import { ErrorMessage, Field } from "formik";

const TextInputField = ({
  errors,
  touched,
  type,
  label,
  name,
  required,
  readOnly,
  classProps,
}) => {
  return (
    <>
      <div className={classProps}>
        {label && (
          <label htmlFor={name} className="text-label">
            {label}
            {required && <span className="text-danger">*</span>}
          </label>
        )}
        <Field
          type={type}
          name={name}
          id={name}
          readOnly={readOnly}
          className={
            errors.name && touched.name
              ? "input-error form-control"
              : "form-control"
          }
        />
        <ErrorMessage
          name={name}
          component="span"
          className="error text-danger"
        />
      </div>
    </>
  );
};

export default TextInputField;
