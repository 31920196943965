import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const InspectionActionButton = ({ inspectionDetails }) => {
    const navigate = useNavigate();
    const redirectToEditPage = () => {
        navigate("/client-inspection/edit/" + inspectionDetails?.inspectionId);
    }

    return (
        <>
            <div className='d-flex align-items-center'>
                <Button variant="primary" size="sm" onClick={() => redirectToEditPage(inspectionDetails?.inspectionId)} >
                    <i className="fa-solid fa-user-pen"></i>
                </Button>
            </div>
        </>
    );
}

export default InspectionActionButton;