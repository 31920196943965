import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Moment from 'react-moment';

import InspectionActionButton from "../../../components/ActionButtons/InspectionActionButton";
import FilterComponent from "../../../components/FilterComponent/Index";
import InspectionMethods from "../../../asyncMethods/InspectionMethods";

const columns = [
  {
    name: "Inspection ID",
    selector: (row) => row.inspectionId,
    sortable: true
  },
  {
    name: "Client Name",
    selector: (row) => row.clientName
  },
  {
    name: "Client Information",
    selector: (row) => row?.clientInformation,
    center: true
  },
  {
    name: "Date and Time",
    selector: (row) => <Moment format="MM/DD/YYYY">{row?.inspectionDateTime}</Moment>,
    center: true
  },
  {
    name: "Vehicle Reg No",
    selector: (row) => row?.vehicleRegNumber,
    center: true
  },
  {
    name: "Vehicle Configuration Key",
    selector: (row) => row?.configuredVehicle,
    center: true
  },
  {
    name: "Odometer Reading",
    selector: (row) => row?.vehicleOdometerReading,
    center: true
  },
  {
    name: "Action",
    selector: (row) => row?.actionButtons,
    center: true
  },
]

const Inspection = () => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const inspectionMethods = InspectionMethods();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // Fetch All ObservationMaster
  useEffect(() => {
    inspectionMethods.fetchAllInspections();
  }, []);



  const data = Array.from(useSelector((state) => state?.inspection?.inspectionList))
    .map((inspection) => ({
      ...inspection,
      actionButtons: (
        <InspectionActionButton
          inspectionDetails={inspection}
        />
      ),
    }))
    .sort((b, a) => a.inspectionId - b.inspectionId);

  const filteredItems = data.filter(
    (item) =>
      ((item.inspectionId || "").toString().toLowerCase().includes(filterText.toString().toLowerCase())) ||
      ((item.clientName || "").toString().toLowerCase().includes(filterText.toString().toLowerCase()))
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);


  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <h5></h5>
        <Button
          onClick={() => navigate("/client-inspection/create")}
          variant="primary"
        >
          <i className="fa-solid fa-plus"></i> New Inspection
        </Button>
      </div>
      <Card>
        <Card.Body>
          <DataTable
            title="Inspection"
            columns={columns}
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle}
            pagination
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Inspection;
