import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import ClientActionButtons from "../../../components/ActionButtons/ClientActionButtons";
import ClientMethods from '../../../asyncMethods/ClientMethods';
import FilterComponent from '../../../components/FilterComponent/Index';

const columnData = [
  { name: "Client ID", selector: "clientId", sortable: true, center: true },
  { name: "Title", selector: "clientTitle", sortable: true },
  { name: "Full Name", selector: "name" },
  { name: "Email", selector: "clientEmailId" },
  { name: "Mobile No", selector: "clientMobileNo" },
  { name: "Company", selector: "clientCompanyName" },
  { name: "Website", selector: "clientWebsite" },
  { name: "Phone", selector: "clientPhoneNo" },
  { name: "GST Type", selector: "gstRegistrationType" },
  { name: "GST No", selector: "clientGstNumber" },
  { name: "PAN No", selector: "clientPanNumber" },
  { name: "Billing Address", selector: "clientBillingAddress" },
  { name: "State", selector: "clientBillingState" },
  { name: "City", selector: "clientBillingCity" },
  { name: "Country", selector: "clientBillingCountry" },
  { name: "PIN", selector: "clientBillingPincode" },
  // { name: "Service Address", selector: "clientServiceAddress" },
  // { name: "State", selector: "clientServiceState" },
  // { name: "City", selector: "clientServiceCity" },
  // { name: "Country", selector: "clientServiceCountry" },
  // { name: "PIN", selector: "clientServicePincode" },
  { name: "Status", selector: "clientActiveStatus" },
  { name: "Action", selector: "actionButtons", center: true },
];

const columns = columnData.map(data => {
  return {
    name: data.name,
    selector: (row) => row[data.selector],
    sortable: data.sortable,
    center: data.center,
  };
})

const Clients = () => {
  const navigate = useNavigate();
  const clientMethods = ClientMethods();

  useEffect(() => {
    clientMethods.fetchAllClients();
  }, []);

  const statusUpdate = (clientId, status) => {
    const data = {
      id: clientId,
      status: status == 1 ? 0 : 1
    }
    clientMethods.clientStatusUpdate(data);
  }

  const data = Array.from(useSelector((state) => state.client.clientList))
    .map((client) => ({
      ...client,
      name: `${client.clientFirstName} ${client.clientMiddleName} ${client.clientLastName}`,
    }))
    .map((client) => ({
      ...client,
      actionButtons: (
        <ClientActionButtons
          onStatusUpdate={statusUpdate}
          clientDetails={client}
        />
      ),
    }))
    .sort((b, a) => a.clientId - b.clientId);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = data.filter(
    (item) =>
      ( item.name.toLowerCase().includes(filterText.toLowerCase())) ||
        item.clientMobileNo.toString().toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <h5></h5>
        <Button
          onClick={() => navigate("/clients/create")}
          variant="primary"
        >
          <i className="fa-solid fa-plus"></i> New Client
        </Button>
      </div>
      <Card>
        <Card.Body>
          <DataTable
            title="Client List"
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Clients;
