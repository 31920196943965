import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import InspectionMethods from "../../../../asyncMethods/InspectionMethods";
import VehicleMethods from "../../../../asyncMethods/VehicleMethods";
import { useSelector } from "react-redux";
import api from "../../../../api";

const Analytical = () => {
  const parameters = useParams();
  const inspectionMethods = InspectionMethods();
  const [array, setArray] = useState([]);
  const [attention, setAttention] = useState([]);
  const [tierSq, setTierSq] = useState([]);
  const vehicleMethods = VehicleMethods();

  // const createSubArrays = (length) => {
  //   var mainArray = [];
  //   //   console.log("firstMainarr", mainArray);
  //   if (!length) {
  //     return mainArray;
  //   }
  //   //   console.log("firstMain", mainArray);
  //   var firstSubarray = [0, 1];
  //   //   console.log("firstSub", firstSubarray);
  //   mainArray.push(firstSubarray);
  //   //   console.log("mainPushSub", mainArray);

  //   var remainingLength = length - 2;
  //   //   console.log("remLen", remainingLength);
  //   var subarrayLength = Math.min(remainingLength, 4);
  //   //   console.log("sublen", subarrayLength);

  //   while (remainingLength > 0) {
  //     var subarray = Array.from(
  //       { length: subarrayLength },
  //       (_, index) => index
  //     );
  //     mainArray.push(subarray);
  //     //   console.log("pushMAin", mainArray);
  //     //     console.log("Sub", subarray);

  //     remainingLength -= subarrayLength;
  //     subarrayLength = Math.min(remainingLength, 4);
  //   }
  //   //   console.log("mainLAst", mainArray);

  //   return mainArray;
  // };

  const createSubArrays = (length, tierSequence) => {
    const mainArray = [];
    let currentIndex = 0;

    for (const tierLength of tierSequence) {
      const subarray = Array.from(
        { length: tierLength },
        (_, index) => index + currentIndex
      );
      mainArray.push(subarray);
      currentIndex += tierLength;
    }

    return mainArray;
  };

  // useEffect(() => {
  //     const array = createSubArrays(4);
  //     setArray(array);
  // }, []);

  useEffect(() => {
    if (parameters.inspectionId) {
      inspectionMethods.fetchInspection(parameters.inspectionId);
    }
  }, []);

  const editInspectionData = useSelector(
    (state) => state.inspection.editInspection
  );
  console.log("editInspection", editInspectionData);

  const tierSequenceString = useSelector(
    (state) => state.inspection.editInspection.tireSequence
  );
  console.log("edit tier", tierSequenceString);

  useEffect(() => {
    if (editInspectionData && editInspectionData.noOfTires % 2 === 0) {
      if (tierSequenceArray !== undefined) {
        initInspectionDetails();
      }
    }
    // if (editInspectionData && ((editInspectionData.noOfStepneys + editInspectionData.noOfTires) % 2) === 0) {
    //     initInspectionDetails();
    // }
  }, [editInspectionData]);

  // useEffect(() => {
  //   if (editInspectionData.vehicleId) {
  //     vehicleMethods.fetchVehicleData(editInspectionData.vehicleId);
  //   }
  // }, [editInspectionData.vehicleId]);

  // const tierSequenceString = useSelector(
  //   (state) => state?.vehicle?.editVehicle?.tireSequence
  // );
  // console.log("edit tierSQ", tierSequenceString);

  // // other api
  // useEffect(() => {
  //   vehicleDatas();
  // }, [editInspectionData]);

  // const vehicleDatas = async () => {
  //   const vehicleManufacturer = editInspectionData.vehicleManufacturer;
  //   const vehicleModel = editInspectionData.vehicleModel;
  //   const vehicleType = editInspectionData.vehicleType;

  //   // console.log("manufacturer", vehicleManufacturer);
  //   // console.log("model", vehicleModel);
  //   // console.log("type", vehicleType);

  //   if (vehicleManufacturer && vehicleModel && vehicleType) {
  //     // console.log("manufacturer", vehicleManufacturer);
  //     // console.log("model", vehicleModel);
  //     // console.log("type", vehicleType);
  //     try {
  //       const resp = await InspectionMethods.getVehicleByFilter(
  //         vehicleManufacturer,
  //         vehicleModel,
  //         vehicleType
  //       );
  //       console.log("respp", resp);
  //     } catch (error) {
  //       console.error("err", error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const vehicleManufacturer = 2;
  //   const vehicleModel = 14;
  //   const vehicleType = 1;

  //   console.log("vehicleManufacturer:", vehicleManufacturer);
  //   console.log("vehicleModel:", vehicleModel);
  //   console.log("vehicleType:", vehicleType);

  //   if (vehicleManufacturer && vehicleModel && vehicleType) {
  //     const apiUrl = `/masterservice/getvehiclebymultiplefilter?vehicleManufacturer=${vehicleManufacturer}&vehicleModel=${vehicleModel}&vehicleType=${vehicleType}`;
  //     console.log("API URL:", apiUrl);

  //     api
  //       .get(apiUrl)
  //       .then((res) => {
  //         console.log("API Response:", res.data.result);
  //         console.log("tierSq", res.data.result.tireSequence);
  //         setTierSq(res.data.result.tireSequence);
  //       })
  //       .catch((err) => {
  //         console.log("API Error:", err);
  //       });
  //   }
  // }, [editInspectionData]);

  // const tierSequenceString2 = useSelector((state) => state);
  // console.log("edit new api state", tierSequenceString2);

  // const tierSequenceString = "4,2,2";
  //const tierSequenceArray = tierSequenceString.split(",").map(Number);

  let tierSequenceArray;
  if (tierSequenceString != undefined) {
    tierSequenceArray = tierSequenceString
      .split(",")
      .map((str) => (str === "null" ? null : Number(str)))
      .filter(Number.isInteger);
  }

  // if (editInspectionData && tierSequenceString !== undefined) {
  //   let tierSequenceArray = [];

  //   if (tierSequenceString !== null) {
  //     tierSequenceArray = tierSequenceString
  //       .split(",")
  //       .map((str) => (str === "null" ? null : Number(str)))
  //       .filter(Number.isInteger);
  //   }
  // }

  const initInspectionDetails = async () => {
    const attention = [];
    const vehicleInspectionDetails =
      await inspectionMethods.getVehicleInspectionDetails(
        parameters.inspectionId
      );
    const response = vehicleInspectionDetails.splice(
      0,
      editInspectionData.noOfTires
    );
    console.log("tilakRes", response);
    let totalTires = await editInspectionData.noOfTires;
    // let totalTires = response.length;
    console.log(totalTires, response.length);
    // if (editInspectionData.noOfTires > response.length) {
    //   totalTires = response.length;
    // }
    // const array = createSubArrays(totalTires);
    const validTierSequenceArray = await tierSequenceArray.slice(0, totalTires);

    const array = createSubArrays(totalTires, validTierSequenceArray);

    //console.log("tilakafterSub", array);
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      console.log("tilakElement", element);
      console.log("ele len", element.length);
      for (let i = 0; i < element.length; i++) {
        let tireNumber;
        const ele = element[i];
        // console.log("tilakEle", ele);
        // const colorIndex =
        //   ele + (index === 1 || index === 0 ? index * 2 : index * 4 - 2);
        const colorIndex = ele;

        //  console.log("tilakColor", colorIndex);

        if (response[colorIndex]) {
          console.log("resp colo", response[colorIndex].recommendationLabel);
          tireNumber = response[colorIndex].tirePosition;
          //   console.log("tierNum", tireNumber);
          const tierReco = response[colorIndex].recommendationLabel;
          const retreadPercentage =
            (response[colorIndex].recoPressure * 100) /
            response[colorIndex].obsPressure;

          let pressureColor;

          if (tierReco == "AllOK") {
            pressureColor = "GREEN";
          } else if (retreadPercentage > 95 && retreadPercentage < 105) {
            pressureColor = "GREEN";
          } else if (
            (retreadPercentage < 95 && retreadPercentage > 90) ||
            (retreadPercentage > 105 && retreadPercentage < 110)
          ) {
            pressureColor = "YELLOW";
          } else {
            if (retreadPercentage > 110) {
              attention.push({
                parameters: "High Pressure",
                tireNumber,
                recommendationLabel: response[colorIndex].recommendationLabel,
              });
            } else {
              attention.push({
                parameters: "Low Pressure",
                tireNumber,
                recommendationLabel: response[colorIndex].recommendationLabel,
              });
            }
            pressureColor = "RED";
          }

          array[index][i] = {
            treadColor:
              response[colorIndex].recommendationLabel == "AllOK"
                ? "GREEN"
                : response[colorIndex].leastTireThicknessAllowedAnalysis,
            obsPressure: response[colorIndex].obsPressure,
            otdMm: response[colorIndex].otdMm,
            ctMm: response[colorIndex].ctMm,
            lstMm: response[colorIndex].lstMm,
            rstMm: response[colorIndex].rstMm,
            tireDamageColor:
              response[colorIndex].recommendationLabel == "AllOK"
                ? "GREEN"
                : "RED",
            pressureColor,
            tireNumber,
          };
          if (response[colorIndex].obsPressure === "RED") {
            attention.push({
              parameters: "Low Tread Depth",
              tireNumber,
              recommendationLabel: response[colorIndex].recommendationLabel,
            });
          } else if (
            response[colorIndex].leastTireThicknessAllowedAnalysis == "RED"
          ) {
            attention.push({
              parameters: "Low Tread Depth",
              tireNumber,
              recommendationLabel: response[colorIndex].recommendationLabel,
            });
          }

          if (response[colorIndex].recommendationLabel) {
            attention.push({
              parameters: response[colorIndex].observationLabel,
              tireNumber,
              recommendationLabel: response[colorIndex].recommendationLabel,
            });
          }
        }
      }
    }
    setAttention(attention);
    setArray(array);
    console.log("tilakArr", array);
    console.log("atten", attention);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 report-analytical">
            <Card
              className="m-0"
              style={{ width: "100", backgroundColor: "#F9EFEF" }}
            >
              <Card.Body className="p-0">
                <div className="d-flex justify-content-between pt-2 px-2 pb-3">
                  <div className="d-flex flex-column justify-content-between w-35">
                    <div className="label">
                      <span>
                        Name and Address of Transport Company:{" "}
                        <span className="label-value">
                          {editInspectionData.clientName}
                        </span>
                      </span>
                    </div>
                    <div className="label">
                      <span>
                        Comapny Name:{" "}
                        <span className="label-value">
                          {editInspectionData.clientInformation
                            ? editInspectionData.clientInformation
                                .split(",")[0]
                                .trim()
                            : ""}
                        </span>
                      </span>
                    </div>

                    <div className="label">
                      <span>
                        Mobile/ Tel.No.:{" "}
                        <span className="label-value">
                          {editInspectionData.clientInformation
                            ? editInspectionData.clientInformation
                                .split(",")
                                .pop()
                            : ""}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-between w-25">
                    <div className="label">
                      Registration No.:{" "}
                      <span className="label-value">
                        {editInspectionData.vehicleRegNumber}
                      </span>
                    </div>
                    <div className="label">
                      Odometer Reading:{" "}
                      <span className="label-value">
                        {editInspectionData.vehicleOdometerReading}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-between w-25">
                    <div className="label">
                      Vehicle Make/Model:{" "}
                      <span className="label-value">
                        {editInspectionData.vehicleModelLabel}
                      </span>
                    </div>
                    <div className="label">
                      Vehicle Configuration:{" "}
                      <span className="label-value">
                        {editInspectionData.configuredVehicle}
                      </span>
                    </div>
                  </div>
                  <div>
                    <img
                      src="/assets/images/mtssWhitelogo.png"
                      alt="MTSS"
                      width="200"
                    />
                  </div>
                </div>

                <div style={{ backgroundColor: "#B9ACAC" }} className="p-3">
                  <div className="row">
                    <div className="col-sm-12 mt-2 d-flex">
                      <div className="table1-section">
                        <div className="pl-2 d-flex justify-content-center position-relative">
                          <div className="w-100">
                            <table className="report-main-table w-100">
                              {array.map((item, i) => (
                                <React.Fragment>
                                  {item.length === 2 && (
                                    <React.Fragment>
                                      <tr>
                                        <td className="w-10"></td>
                                        <td
                                          colSpan={2}
                                          className="w-10 text-center pt-4"
                                        >
                                          {item[0].tireNumber}
                                        </td>
                                        <td className="w-3"></td>
                                        <td className="w-8"></td>
                                        <td colspan="5" className="w-38"></td>
                                        <td className="w-8 text-center pt-4">
                                          {item[1].tireNumber}
                                        </td>
                                        <td className="w-3"></td>
                                        <td colSpan={2} className="w-10"></td>
                                        <td className="w-10"></td>
                                      </tr>
                                      <tr>
                                        <td className="w-10"></td>
                                        <td className="w-10" colSpan={2}>
                                          <div className="d-flex justify-content-end">
                                            <table
                                              className="w-100"
                                              border={1}
                                              style={{
                                                borderColor: "#000000",
                                                backgroundColor: "#ffffff",
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{
                                                      height: "34px",
                                                      width: "70%",
                                                    }}
                                                    className="pl-1 pr-1"
                                                  >
                                                    {item[0].obsPressure}
                                                  </td>
                                                  <td rowspan="3">
                                                    <div className="d-flex flex-column justify-content-between align-items-center">
                                                      <div
                                                        style={{
                                                          height: "30px",
                                                        }}
                                                        className="pt-1"
                                                      >
                                                        <img
                                                          src="/assets/images/pressuresym.png"
                                                          alt="pressureSymp"
                                                        />
                                                      </div>
                                                      <div
                                                        style={{
                                                          height: "32px",
                                                        }}
                                                      >
                                                        <img
                                                          src="/assets/images/tiresym.png"
                                                          alt="tireSym"
                                                        />
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      height: "34px",
                                                      width: "70%",
                                                    }}
                                                    className="pl-1 pr-1"
                                                  >
                                                    {item[0].lstMm >
                                                    item[0].rstMm
                                                      ? item[0].rstMm
                                                      : item[0].lstMm >
                                                        item[0].ctMm
                                                      ? item[0].ctMm
                                                      : item[0].lstMm}
                                                    {/* {item[0].otdMm} */}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                        <td className="w-3"></td>
                                        <td className="w-8">
                                          <div className="mt-2 tier-table-section">
                                            <div className="position-absolute tier-img">
                                              <img
                                                src="/assets/images/tire.png"
                                                alt="tire"
                                              />
                                            </div>
                                            <div className="d-flex w-100 justify-content-end pt-1 ">
                                              <table
                                                border={1}
                                                style={{
                                                  borderColor: "#000000",
                                                  width: "60%",
                                                }}
                                              >
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[0].pressureColor ==
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[0]
                                                              .pressureColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[0].treadColor ==
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[0]
                                                              .treadColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[0]
                                                          .tireDamageColor ==
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[0]
                                                              .tireDamageColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                              </table>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="w-38" colspan="5">
                                          <div className="pb-3">
                                            <div
                                              className="d-flex justify-content-center pb-2"
                                              style={{ gap: "30px" }}
                                            >
                                              <div>F</div>
                                              <div>{i + 1}</div>
                                            </div>
                                            <div className="hr-center pl-1 pr-1"></div>
                                          </div>
                                        </td>
                                        <td className="w-8">
                                          <div className="mt-2 tier-table-section">
                                            <div className="position-absolute tier-img">
                                              <img
                                                src="/assets/images/tire.png"
                                                alt="tire"
                                              />
                                            </div>
                                            <div className="d-flex  w-100 justify-content-end  pt-1 ">
                                              <table
                                                border={1}
                                                style={{
                                                  borderColor: "#000000",
                                                  width: "60%",
                                                }}
                                              >
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[1]
                                                          .pressureColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[1]
                                                              .pressureColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[1].treadColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[1]
                                                              .treadColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[1]
                                                          .tireDamageColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[1]
                                                              .tireDamageColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                              </table>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="w-3"></td>
                                        <td colSpan={2} className="w-10">
                                          <table
                                            className="w-100"
                                            border={1}
                                            style={{
                                              borderColor: "#000000",
                                              backgroundColor: "#ffffff",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td rowspan="3">
                                                  <div className="d-flex flex-column justify-content-between align-items-center">
                                                    <div
                                                      style={{ height: "30px" }}
                                                      className="pt-1"
                                                    >
                                                      <img
                                                        src="/assets/images/pressuresym.png"
                                                        alt="pressureSymp"
                                                      />
                                                    </div>
                                                    <div
                                                      style={{ height: "32px" }}
                                                    >
                                                      <img
                                                        src="/assets/images/tiresym.png"
                                                        alt="tireSym"
                                                      />
                                                    </div>
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "70%",
                                                    borderColor: "#000000",
                                                    backgroundColor: "#ffffff",
                                                  }}
                                                  className="pl-1 pr-1"
                                                >
                                                  {item[1].obsPressure}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "70%",
                                                    borderColor: "#000000",
                                                    backgroundColor: "#ffffff",
                                                  }}
                                                  className="pl-1 pr-1"
                                                >
                                                  {item[1].lstMm > item[1].rstMm
                                                    ? item[1].rstMm
                                                    : item[1].lstMm >
                                                      item[1].ctMm
                                                    ? item[1].ctMm
                                                    : item[1].lstMm}
                                                  {/* {item[1].otdMm} */}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td className="w-10"></td>
                                      </tr>
                                    </React.Fragment>
                                  )}
                                  {item.length === 4 && (
                                    <React.Fragment>
                                      <tr>
                                        <td className="w-10"></td>
                                        <td className="w-2"></td>
                                        <td className="w-8 pt-4 text-center">
                                          {item[0].tireNumber}
                                        </td>
                                        <td className="w-3"></td>
                                        <td className="w-8 pt-4 text-center">
                                          {item[1].tireNumber}
                                        </td>
                                        <td className="w-3"></td>
                                        <td className="w-10"></td>
                                        <td className="w-12"></td>
                                        <td className="w-10"></td>
                                        <td className="w-3 "></td>
                                        <td className="w-8 pt-4 text-center">
                                          {item[2].tireNumber}
                                        </td>
                                        <td className="w-3"></td>
                                        <td className="w-8 pt-4 text-center">
                                          {item[3].tireNumber}
                                        </td>
                                        <td className="w-2"></td>
                                        <td className="w-10"></td>
                                      </tr>
                                      <tr>
                                        <td className="w-10">
                                          <table
                                            className="w-100"
                                            border={1}
                                            style={{
                                              borderColor: "#000000",
                                              backgroundColor: "#ffffff",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "70%",
                                                    borderColor: "#000000",
                                                    backgroundColor: "#ffffff",
                                                  }}
                                                  className="pl-1 pr-1"
                                                >
                                                  {item[0].obsPressure}
                                                </td>
                                                <td rowspan="3">
                                                  <div className="d-flex flex-column justify-content-between align-items-center">
                                                    <div
                                                      style={{ height: "30px" }}
                                                      className="pt-1"
                                                    >
                                                      <img
                                                        src="/assets/images/pressuresym.png"
                                                        alt="pressureSymp"
                                                      />
                                                    </div>
                                                    <div
                                                      style={{ height: "32px" }}
                                                    >
                                                      <img
                                                        src="/assets/images/tiresym.png"
                                                        alt="tireSym"
                                                      />
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "70%",
                                                    borderColor: "#000000",
                                                    backgroundColor: "#ffffff",
                                                  }}
                                                  className="pl-1 pr-1"
                                                >
                                                  {item[0].lstMm > item[0].rstMm
                                                    ? item[0].rstMm
                                                    : item[0].lstMm >
                                                      item[0].ctMm
                                                    ? item[0].ctMm
                                                    : item[0].lstMm}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td className="w-2"></td>
                                        <td className="w-8">
                                          <div className="mt-2 tier-table-section">
                                            <div className="position-absolute tier-img">
                                              <img
                                                src="/assets/images/tire.png"
                                                alt="tire"
                                              />
                                            </div>
                                            <div className="d-flex   w-100 justify-content-end  pt-1 ">
                                              <table
                                                border={1}
                                                style={{
                                                  borderColor: "#000000",
                                                  width: "60%",
                                                }}
                                              >
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[0]
                                                          .pressureColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[0]
                                                              .pressureColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[0].treadColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[0]
                                                              .treadColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[0]
                                                          .tireDamageColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[0]
                                                              .tireDamageColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                              </table>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="w-3 pt-2">
                                          <div className="hr-child pl-1 pr-1"></div>
                                        </td>
                                        <td className="w-8">
                                          <div className="mt-2 tier-table-section">
                                            <div className="position-absolute tier-img">
                                              <img
                                                src="/assets/images/tire.png"
                                                alt="tire"
                                              />
                                            </div>
                                            <div
                                              className="d-flex   w-100 justify-content-end  pt-1 "
                                              style={{ width: "95px" }}
                                            >
                                              <table
                                                border={1}
                                                style={{
                                                  borderColor: "#000000",
                                                  width: "60%",
                                                }}
                                              >
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[1]
                                                          .pressureColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[1]
                                                              .pressureColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[1].treadColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[1]
                                                              .treadColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[1]
                                                          .tireDamageColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[1]
                                                              .tireDamageColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                              </table>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="w-3 pt-2">
                                          <div className="hr-child pl-1 pr-1"></div>
                                        </td>
                                        <td className="w-10">
                                          <table
                                            className="w-100"
                                            border={1}
                                            style={{
                                              borderColor: "#000000",
                                              backgroundColor: "#ffffff",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td rowspan="3">
                                                  <div className="d-flex flex-column justify-content-between align-items-center">
                                                    <div
                                                      style={{ height: "30px" }}
                                                      className="pt-1"
                                                    >
                                                      <img
                                                        src="/assets/images/pressuresym.png"
                                                        alt="pressureSymp"
                                                      />
                                                    </div>
                                                    <div
                                                      style={{ height: "32px" }}
                                                    >
                                                      <img
                                                        src="/assets/images/tiresym.png"
                                                        alt="tireSym"
                                                      />
                                                    </div>
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "70%",
                                                    borderColor: "#000000",
                                                    backgroundColor: "#ffffff",
                                                  }}
                                                  className="pl-1 pr-1"
                                                >
                                                  {item[1].obsPressure}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "70%",
                                                    borderColor: "#000000",
                                                    backgroundColor: "#ffffff",
                                                  }}
                                                  className="pl-1 pr-1"
                                                >
                                                  {item[1].lstMm > item[1].rstMm
                                                    ? item[1].rstMm
                                                    : item[1].lstMm >
                                                      item[1].ctMm
                                                    ? item[1].ctMm
                                                    : item[1].lstMm}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td className="w-12">
                                          <div className="pb-3">
                                            <div
                                              className="d-flex justify-content-center pb-2"
                                              style={{ gap: "32px" }}
                                            >
                                              <div>R1</div>
                                              <div>{i + 1}&nbsp; </div>
                                            </div>
                                            <div className="hr-child pl-1 pr-1"></div>
                                          </div>
                                        </td>
                                        <td className="w-10">
                                          <table
                                            className="w-100"
                                            border={1}
                                            style={{
                                              borderColor: "#000000",
                                              backgroundColor: "#ffffff",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "70%",
                                                    borderColor: "#000000",
                                                    backgroundColor: "#ffffff",
                                                  }}
                                                  className="pl-1 pr-1"
                                                >
                                                  {item[2].obsPressure}
                                                </td>
                                                <td rowspan="3">
                                                  <div className="d-flex flex-column justify-content-between align-items-center">
                                                    <div
                                                      style={{ height: "30px" }}
                                                      className="pt-1"
                                                    >
                                                      <img
                                                        src="/assets/images/pressuresym.png"
                                                        alt="pressureSymp"
                                                      />
                                                    </div>
                                                    <div
                                                      style={{ height: "32px" }}
                                                    >
                                                      <img
                                                        src="/assets/images/tiresym.png"
                                                        alt="tireSym"
                                                      />
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "70%",
                                                    borderColor: "#000000",
                                                    backgroundColor: "#ffffff",
                                                  }}
                                                  className="pl-1 pr-1"
                                                >
                                                  {item[2].lstMm > item[2].rstMm
                                                    ? item[2].rstMm
                                                    : item[2].lstMm >
                                                      item[2].ctMm
                                                    ? item[2].ctMm
                                                    : item[2].lstMm}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td className="w-3 pt-2">
                                          <div className="hr-child pl-1 pr-1"></div>
                                        </td>
                                        <td className="w-8">
                                          <div className="mt-2 tier-table-section">
                                            <div className="position-absolute tier-img">
                                              <img
                                                src="/assets/images/tire.png"
                                                alt="tire"
                                              />
                                            </div>
                                            <div className="d-flex   w-100 justify-content-end  pt-1 ">
                                              <table
                                                border={1}
                                                style={{
                                                  borderColor: "#000000",
                                                  width: "60%",
                                                }}
                                              >
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[2]
                                                          .pressureColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[2]
                                                              .pressureColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[2].treadColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[2]
                                                              .treadColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[2]
                                                          .tireDamageColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[2]
                                                              .tireDamageColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                              </table>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="w-3 pt-2">
                                          <div className="hr-child pl-1 pr-1"></div>
                                        </td>
                                        <td className="w-8">
                                          <div className="mt-2 tier-table-section">
                                            <div className="position-absolute tier-img">
                                              <img
                                                src="/assets/images/tire.png"
                                                alt="tire"
                                              />
                                            </div>
                                            <div className="d-flex   w-100 justify-content-end  pt-1 ">
                                              <table
                                                border={1}
                                                style={{
                                                  borderColor: "#000000",
                                                  width: "60%",
                                                }}
                                              >
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[3]
                                                          .pressureColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[3]
                                                              .pressureColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[3].treadColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[3]
                                                              .treadColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="tire-table"
                                                    style={{
                                                      backgroundColor:
                                                        item[3]
                                                          .tireDamageColor ===
                                                        "GREEN"
                                                          ? "#34E834"
                                                          : item[3]
                                                              .tireDamageColor ===
                                                            "RED"
                                                          ? "#E10A0A"
                                                          : "#F5F509",
                                                    }}
                                                  ></td>
                                                </tr>
                                              </table>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="w-2"></td>
                                        <td className="w-10">
                                          <table
                                            className="w-100"
                                            border={1}
                                            style={{
                                              borderColor: "#000000",
                                              backgroundColor: "#ffffff",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td rowspan="3">
                                                  <div className="d-flex flex-column justify-content-between align-items-center">
                                                    <div
                                                      style={{
                                                        height: "30px",
                                                        borderColor: "#000000",
                                                        backgroundColor:
                                                          "#ffffff",
                                                      }}
                                                      className="pt-1"
                                                    >
                                                      <img
                                                        src="/assets/images/pressuresym.png"
                                                        alt="pressureSymp"
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "32px",
                                                        borderColor: "#000000",
                                                        backgroundColor:
                                                          "#ffffff",
                                                      }}
                                                    >
                                                      <img
                                                        src="/assets/images/tiresym.png"
                                                        alt="tireSym"
                                                      />
                                                    </div>
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "70%",
                                                    borderColor: "#000000",
                                                    backgroundColor: "#ffffff",
                                                  }}
                                                  className="pl-1 pr-1"
                                                >
                                                  {item[3].obsPressure}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "70%",
                                                    borderColor: "#000000",
                                                    backgroundColor: "#ffffff",
                                                  }}
                                                  className="pl-1 pr-1"
                                                >
                                                  {item[3].lstMm > item[3].rstMm
                                                    ? item[3].rstMm
                                                    : item[3].lstMm >
                                                      item[3].ctMm
                                                    ? item[3].ctMm
                                                    : item[3].lstMm}
                                                  {/* {item[3].otdMm} */}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              ))}
                            </table>
                          </div>

                          <div className="d-flex position-absolute h-100">
                            <img
                              src="/assets/images/newArrow.png"
                              alt="Arrow"
                            />
                          </div>
                        </div>

                        <div
                          className={
                            "pl-2  d-flex " +
                            (array.length > 3 ? "mt-5" : "mt-5")
                          }
                        >
                          <div className="d-flex w-75">
                            <div className="text-center">
                              <div className="h-64">
                                <img
                                  src="/assets/images/whitePressureSym.png"
                                  alt="pressureSymp"
                                  style={{ height: "62px", width: "54px" }}
                                />
                              </div>
                              <div className="mt-3">Pressure</div>
                            </div>
                            <div className="pl-4 text-center">
                              <div className="h-64">
                                <img
                                  src="/assets/images/tiresym.png"
                                  alt="tireSym"
                                  style={{ height: "49px", width: "29px" }}
                                />
                              </div>
                              <div className="mt-3">Tread</div>
                            </div>
                            <div className="pl-4 text-center">
                              <div className="h-64">
                                <img
                                  src="/assets/images/damagesym.png"
                                  alt="damageSym"
                                  style={{ height: "45px", width: "55px" }}
                                />
                              </div>
                              <div className="mt-3">Tire damage</div>
                            </div>
                          </div>
                          <div className="d-flex w-50 justify-content-center">
                            <div className="text-center">
                              <div className="h-64 d-flex justify-content-center">
                                <div
                                  className="indicator-square"
                                  style={{ backgroundColor: "#34E834" }}
                                ></div>
                              </div>
                              <div className="mt-3">All Ok</div>
                            </div>
                            <div className="pl-4 text-center">
                              <div className="h-64 d-flex justify-content-center">
                                <div
                                  className="indicator-square"
                                  style={{ backgroundColor: "#F5F509" }}
                                ></div>
                              </div>
                              <div className="mt-3">Needs Attention</div>
                            </div>
                            <div className="pl-4 text-center">
                              <div className="h-64 d-flex justify-content-center">
                                <div
                                  className="indicator-square"
                                  style={{ backgroundColor: "#E10A0A" }}
                                ></div>
                              </div>
                              <div className="mt-3">Problem</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table2-section d-flex justify-content-end align-items-center ">
                        <div className="pl-5 w-100">
                          <h4>Tires Needing Attention</h4>
                          <table border={1} className="w-100">
                            <tbody>
                              <tr>
                                <td>Tire Parameters</td>
                                <td>Tire No.</td>
                                <td>Comments</td>
                              </tr>
                              {attention
                                .filter(
                                  (item) => item.recommendationLabel !== "AllOK"
                                )
                                .map((item, i) => (
                                  <React.Fragment key={i}>
                                    <tr>
                                      <td>{item.parameters}</td>
                                      <td>{item.tireNumber}</td>
                                      <td>{item.recommendationLabel}</td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                            </tbody>
                            <tfoot></tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between p-3">
                  <div
                    className="d-flex flex-column justify-content-between"
                    style={{ gap: "6px" }}
                  >
                    <div>Driver Name</div>
                    <div>Mobile No.</div>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-between"
                    style={{ gap: "6px" }}
                  >
                    <div>Maintenance Manager Name</div>
                    <div>Mobile No.</div>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-between pr-5"
                    style={{ gap: "6px" }}
                  >
                    <div>Milan Tire Service & Solution</div>
                    <div>Mobile No.</div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytical;
