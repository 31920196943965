import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FETCH_ALL_INSPECTION_TYPE, SET_EDIT_INSPECTION_TYPE, SET_INSPECTION_DETAILS } from "../redux/constants/InspectionTypes";
import { useNavigate } from "react-router-dom";
import api from "../api";

const InspectionMethods = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchAllInspections = () => {
    return api.get("/vehicleinspection/getallvehicleinspection?page=0&size=20000&sortBy=inspectionDateTime")
      .then(res => {
        dispatch({
          type: FETCH_ALL_INSPECTION_TYPE,
          payload: res.data.result
        });
      }).catch(err => {
        console.log(err);
      })
  }

  const storeInspection = (data) => {
    return api.post("/vehicleinspection/createvehicleinspection", data).then(res => {
      if (res.data.statusCode == 201) {
        toast.success("Inspection created Successful.");
        navigate(`/client-inspection/edit/${res.data.result.id}`);
        return;
      } else {
        toast.error(res.data.statusDescription);
        return;
      }
    }).catch(err => console.log(err));
  }

  const fetchInspection = (inspectionId = 1) => {
    return api.get(`/vehicleinspection/getvehicleinspection?inspectionId=` + inspectionId)
      .then(res => {
        dispatch({
          type: SET_EDIT_INSPECTION_TYPE,
          payload: res.data.result
        });
        return res.data.result
      }).catch(err => {
        console.log(err);
      })
  }

  const getVehicleByFilter = (vehicleManufacturer = 1, vehicleModel = 1, vehicleType = 1) => {
    return api.get(`/masterservice/getvehiclebymultiplefilter?vehicleManufacturer=${vehicleManufacturer}&vehicleModel=${vehicleModel}&vehicleType=${vehicleType}`)
      .then(res => {
        return res.data.result;
      }).catch(err => {
        console.log(err);
      })
  }

  const updateInspection = (inspectionId, masterData) => {
    const headers = {
      "Content-Type": "application/json"
    };
    return api.put(`/vehicleinspection/updatevehicleinspection?inspectionId=` + inspectionId, masterData, { headers })
      .then(res => {
        if (res.data.statusCode == 200) {
          toast.success("Inspection Data updated Successful.");
          navigate('/client-inspection');
          return;
        }
        toast.error(res.data.statusDescription);
      }).catch(err => {
        console.log(err);
        toast.error("Fetch Failed.");
      })
  }

  const getVehicleInspectionDetails = (inspectionId = 1) => {
    return api.get(`/vehicleinspection/getvehicleinspectiondetails?inspectionId=` + inspectionId)
      .then(res => {
        console.log("🚀 ~ file: InspectionMethods.js:84 ~ getVehicleInspectionDetails ~ res.data.result:", res.data.result)
        dispatch({
          type: SET_INSPECTION_DETAILS,
          payload: (res.data.result) ? res.data.result : []
        });
        return (res.data.result) ? res.data.result : [];
      }).catch(err => {
        console.log(err);
      })
  }

  const saveVehicleInspectionDetails = (data, type, inspectionId) => {
    return api.post(`/vehicleinspection/savevehicleinspectiondetails?inspectionId=${inspectionId}&inspectionStatus=${type}`, data).then(res => {
      if (res.data.statusCode == 200) {
        navigate(`/client-inspection`);
        return;
      } else {
        toast.error(res.data.statusDescription);
        return;
      }
    }).catch(err => console.log(err));
  }

  const uploadFile = (data) => {
    const config = {     
      headers: { 'content-type': 'multipart/form-data' }
    }
    return api.post(`/fileservice/uploadfile`, data, config).then(res => {
      return res.data.result
    }).catch(err => console.log(err));
  }

  return {
    fetchAllInspections,
    storeInspection,
    fetchInspection,
    getVehicleByFilter,
    updateInspection,
    getVehicleInspectionDetails,
    saveVehicleInspectionDetails,
    uploadFile
  };
}

export default InspectionMethods