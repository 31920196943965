import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_INSPECTION_DETAILS } from "../../../redux/constants/InspectionTypes";

import VehicleTireMakeDataMethod from "../../../asyncMethods/mst_action/VehicleTireMakeDataMethod";
import ObservationCategoryMaster from "../../../asyncMethods/ObservationCategoryMaster";
import OperationalMasterMethod from "../../../asyncMethods/OperationalMasterMethod";
import TireConfigurationMasterMethod from "../../../asyncMethods/TireConfigurationMasterMethod";
import InspectionMethods from "../../../asyncMethods/InspectionMethods";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";
const weekFormat = "MM/DD";
const monthFormat = "YYYY/MM";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];
const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;
const customWeekStartEndFormat = (value) =>
  `${dayjs(value).startOf("week").format(weekFormat)} ~ ${dayjs(value)
    .endOf("week")
    .format(weekFormat)}`;

const TableForm = () => {
  const parameters = useParams();
  const dispatch = useDispatch();
  const [selectedObservationCategory, setSelectedObservationCategory] =
    useState("normal");
  const [inspectionData, setInspectionData] = useState([]);
  const [tireData, setTireData] = useState({});
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [selectedCellIndex, setSelectedCellIndex] = useState(-1);
  const [selectedTireMake, setSelectedTireMake] = useState("");
  const [selectedTireSize, setSelectedTireSize] = useState("");
  const [selectedTirePattern, setSelectedTirePattern] = useState("");
  const [selectedTireThickness, setSelectedTireThickness] = useState(""); // State for tire thickness
  const [tableData, setTableData] = useState([]);
  const [tireThicknessValues, setTireThicknessValues] = useState([]);
  const [selectedAllOkRows, setSelectedAllOkRows] = useState([]);
  const [obb, setObb] = useState(0);
  const [obb2, setObb2] = useState(0);

  const [selectedCell, setSelectedCell] = useState({
    rowIndex: null,
    columnIndex: null,
  });

  const tyreTypes = [
    {
      id: "1",
      label: "New",
    },
    {
      id: "2",
      label: "Retreated",
    },
  ];

  const inspectionMethods = InspectionMethods();
  const vehicleTireMakeMethod = VehicleTireMakeDataMethod();
  const observationCategoryMaster = ObservationCategoryMaster();
  const operationalMasterMethod = OperationalMasterMethod();
  const tireConfigurationMasterMethod = TireConfigurationMasterMethod();
  const inspectionDetails = Array.from(
    useSelector((state) => state.inspection.inspectionDetails)
  );
  // console.log("til", inspectionDetails);

  const tireMake = Array.from(
    useSelector((state) => state?.vehicleTireMake?.vehicleTireMakeList)
  ).filter((s) => s.activeStatus == 1);
  const observationCategoryTypeOption = Array.from(
    useSelector(
      (state) => state?.observationCategoryMaster?.observationCategoryMasterList
    )
  ).filter((s) => s.activeStatus == 1);
  const observations = Array.from(
    useSelector((state) => state?.operational?.operationalMasterList)
  ).filter((s) => s.activeStatus == 1);
  const tireConfigurationMaster = Array.from(
    useSelector(
      (state) => state?.tireConfigurationMaster?.tireConfigurationMasterList
    )
  );

  //console.log("tier red", tireConfigurationMaster);

  useEffect(() => {
    initInspectionDetails();
    observationCategoryMaster.fetchAllObservationCategoryMasterData();
    vehicleTireMakeMethod.fetchAllVehicleTireMakeData();
    operationalMasterMethod.fetchAllOperationalMasterData();
    tireConfigurationMasterMethod.fetchAllTireConfigurationMasterData();
  }, []);

  // useEffect(() => {
  //   if (tireConfigurationMaster && tireConfigurationMaster.length) {
  //     const obj = {};

  //     for (let index = 0; index < tireConfigurationMaster.length; index++) {
  //       const element = tireConfigurationMaster[index];
  //       element.tireMake = element.tireMakeId.tireMakeId;
  //       if (!obj[element.tireMake]) {
  //         obj[element.tireMake] = {
  //           tireSize: [],
  //           [element.tireSize]: {
  //             tirePattern: [],
  //           },
  //         };
  //       }

  //       if (obj[element.tireMake].tireSize.indexOf(element.tireSize) === -1) {
  //         obj[element.tireMake].tireSize.push(element.tireSize);
  //         obj[element.tireMake][element.tireSize] = {
  //           tirePattern: [],
  //         };
  //       }

  //       if (
  //         obj[element.tireMake][element.tireSize].tirePattern.indexOf(
  //           element.tirePattern
  //         ) === -1
  //       ) {
  //         obj[element.tireMake][element.tireSize].tirePattern.push(
  //           element.tirePattern
  //         );
  //       }
  //     }
  //     setTireData(obj);
  //   }
  // }, [tireConfigurationMaster]);

  useEffect(() => {
    if (tireConfigurationMaster && tireConfigurationMaster.length) {
      const obj = {};

      for (const element of tireConfigurationMaster) {
        element.tireMake = element.tireMakeId.tireMakeId;
        if (!obj[element.tireMake]) {
          obj[element.tireMake] = {
            tireSize: [],
            [element.tireSize]: {
              tirePattern: [],
              tireThickness: [],
            },
          };
        }

        if (obj[element.tireMake].tireSize.indexOf(element.tireSize) === -1) {
          obj[element.tireMake].tireSize.push(element.tireSize);
          obj[element.tireMake][element.tireSize] = {
            tirePattern: [],
            tireThickness: [],
          };
        }

        if (
          obj[element.tireMake][element.tireSize].tirePattern.indexOf(
            element.tirePattern
          ) === -1
        ) {
          obj[element.tireMake][element.tireSize].tirePattern.push(
            element.tirePattern
          );
        }

        if (
          obj[element.tireMake][element.tireSize].tireThickness.indexOf(
            element.tireThickness
          ) === -1
        ) {
          obj[element.tireMake][element.tireSize].tireThickness.push(
            element.tireThickness
          );
        }
      }
      setTireData(obj);
    }
  }, [tireConfigurationMaster]);

  const handleTireMakeChange = (e) => {
    const newSelectedTireMake = e.target.value;
    setSelectedTireMake(newSelectedTireMake);
    console.log("setMake: ", newSelectedTireMake);
    setSelectedTireThickness(""); // Reset tire thickness when tire make changes
  };

  // Handler for tire size selection
  const handleTireSizeChange = (e) => {
    const newSelectedTireSize = e.target.value;
    setSelectedTireSize(newSelectedTireSize);
    console.log("setMake 2: ", newSelectedTireSize);
    setSelectedTireThickness(""); // Reset tire thickness when tire size changes
  };

  // Handler for tire pattern selection
  // const handleTirePatternChange = (e, rowIndex) => {
  //   const newSelectedTirePattern = e.target.value;
  //   setSelectedTirePattern(newSelectedTirePattern);

  //   // Find the corresponding tire configuration based on selected options
  //   const matchingTireConfig = tireConfigurationMaster.find(
  //     (config) =>
  //       config.tireMakeId.tireMakeId === parseInt(selectedTireMake) &&
  //       config.tireSize === selectedTireSize &&
  //       config.tirePattern === newSelectedTirePattern
  //   );

  //   if (matchingTireConfig) {
  //     const matchedTireThickness = matchingTireConfig.tireThickness;

  //     // Update the tire thickness value for the specific row
  //     const updatedTireThicknessValues = [...tireThicknessValues];
  //     updatedTireThicknessValues[rowIndex] = matchedTireThickness;
  //     setTireThicknessValues(updatedTireThicknessValues);

  //     // Also update the inspection details with the matched OTD value
  //     const updatedInspectionDetails = [...inspectionDetails];
  //     updatedInspectionDetails[rowIndex].otdMm = matchedTireThickness;
  //     setInspectionDetails(updatedInspectionDetails);
  //   }
  // };

  const handleTirePatternChange = (e, rowIndex) => {
    const newSelectedTirePattern = e.target.value;
    setSelectedTirePattern(newSelectedTirePattern);
  };

  //console.log("obb", observationCategoryTypeOption);
  //console.log("obb2", observations);

  useEffect(() => {
    const obbId = observationCategoryTypeOption.find(
      (o) => o.observationCategoryType == "ALLOK"
    );
    setObb(obbId?.observationCategoryId);
    // console.log(obbId?.observationCategoryId);
  }, [observationCategoryTypeOption]);

  useEffect(() => {
    const obbId = observations.find(
      (o) => o.observationCategoryType == "ALLOK"
    );
    setObb2(obbId?.observationId);
    //console.log(obbId?.observationId);
  }, [observations]);

  const handleAllOkChange = (e, rowIndex) => {
    const updatedDetails = [...inspectionDetails];
    updatedDetails[rowIndex].allOk = "ALLOK";

    if (e.target.checked) {
      // Add the rowIndex to the selectedAllOkRows state
      setSelectedAllOkRows((prevSelectedRows) => [
        ...prevSelectedRows,
        rowIndex,
      ]);

      if (obb && obb2) {
        const allOkObservationCategoryId = obb;
        const allobservationId = obb2;
        updatedDetails[rowIndex].observationCategory =
          allOkObservationCategoryId;
        updatedDetails[rowIndex].observation = allobservationId;
      }

      // Change the values of the select options to "All OK" when the checkbox is selected
      // const allOkObservationCategoryId = 14;
      // const allobservationId = 11;
      // updatedDetails[rowIndex].observationCategory = allOkObservationCategoryId;
      // updatedDetails[rowIndex].observation = allobservationId;
      updatedDetails[rowIndex].recommendationLabel = "ALLOK";
      updatedDetails[rowIndex].observationLabel = "AllOK";
      updatedDetails[rowIndex].observationCategoryLabel = "ALLOK";
      updatedDetails[rowIndex].recommendationLabel = "AllOK";
    } else {
      // Remove the rowIndex from the selectedAllOkRows state
      setSelectedAllOkRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedRow) => selectedRow !== rowIndex)
      );

      // If the checkbox is not checked, remove the 'allOk' property from the object
    }
    delete updatedDetails[rowIndex].allOk;

    // Change the values of the select options to "All OK" when the checkbox is selected
    // if (e.target.checked) {
    //   updatedDetails[rowIndex].observationCategory = "AllOK";
    //   updatedDetails[rowIndex].observation = "AllOK";
    //   updatedDetails[rowIndex].recommendationLabel = "AllOK";

    //   // Remove the 'allOk' property if it exists
    //   if (updatedDetails[rowIndex].hasOwnProperty("allOk")) {
    //     delete updatedDetails[rowIndex].allOk;
    //   }
    // } else {
    //   // If the checkbox is not checked, remove the 'allOk' property from the object
    //   delete updatedDetails[rowIndex].allOk;
    // }

    // Now, updatedDetails contains the modified data without the 'allOk' property
    console.log(updatedDetails);
  };

  const initInspectionDetails = async () => {
    if (parameters.inspectionId) {
      const response = await inspectionMethods.getVehicleInspectionDetails(
        parameters.inspectionId
      );
      console.log("tilakResp" + response);
      prepareData(response);
    }
  };

  const prepareData = async (response) => {
    //console.log("tilakRes" + response);
    const inspectionEditData = await inspectionMethods.fetchInspection(
      parameters.inspectionId
    );
    const rows = [];
    for (
      let index = response.length;
      index <
      Number(inspectionEditData.noOfStepneys) +
        Number(inspectionEditData.noOfTires);
      index++
    ) {
      rows.push({
        inspectionId: parameters.inspectionId,
        inspectionDetailIndex: index + 1,
        tirePosition: "",
        tireType: "",
        tireTypeLabel: "",
        tireMake: "",
        tireMakeLabel: "",
        tireOriginalFitmentDate: "",
        tireSerialNo: "",
        // "otdProjectedMileage": "4",
        leastTireThicknessAllowed: "3",
        odometerReadingWhenFitted: "",
        tireSize: "",
        tirePattern: "",
        tireRemovalDate: "",
        odometerReadingWhenRemoved: "",
        recoPressure: "",
        obsPressure: "",
        otdMm: "",
        lstMm: "",
        ctMm: "",
        rstMm: "",
        observationCategory: "",
        observationCategoryLabel: "",
        observation: "",
        observationLabel: "",
        recommendationLabel: "",
        tireImageUrl: "",
      });
    }
    const data = [...response, ...rows];
    // console.log("tialk" + data);
    dispatch({
      type: SET_INSPECTION_DETAILS,
      payload: data,
    });
  };

  const handleChange = (e, date, dateString, i, labelType) => {
    if (labelType === "tireRemovalDate") {
      const parts = dateString.split("/");
      inspectionDetails[
        i
      ].tireRemovalDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    } else if (labelType === "tireOriginalFitmentDate") {
      // Convert dateString from "DD/MM/YYYY" to "YYYY-MM-DD"
      const parts = dateString.split("/");
      inspectionDetails[
        i
      ].tireOriginalFitmentDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    } else if (e && e.target.name) {
      inspectionDetails[i][e.target.name] = e.target.value;
      // Remove the 'allOk' property if it exists
      if (inspectionDetails[i].hasOwnProperty("allOk")) {
        delete inspectionDetails[i].allOk;
      }

      switch (labelType) {
        case "tireTypeLabel":
          let selectedType = tyreTypes.find((t) => t.id == e.target.value);
          inspectionDetails[i][labelType] = selectedType.label;
          break;
        case "tireMakeLabel":
          let selectedMake = tireMake.find(
            (t) => t.tireMakeId == e.target.value
          );
          inspectionDetails[i][labelType] = selectedMake.tireMakeName;
          break;
        case "observationCategoryLabel":
          let selectedCategory = observationCategoryTypeOption.find(
            (t) => t.observationCategoryId == e.target.value
          );
          inspectionDetails[i][labelType] =
            selectedCategory.observationCategoryType;
          break;
        case "observationLabel":
          let selectedObservation = observations.find(
            (t) => t.observationId == e.target.value
          );
          inspectionDetails[i][labelType] = selectedObservation.observationName;
          inspectionDetails[i]["recommendationLabel"] =
            selectedObservation.observationRecommendation;
          break;
        case "tirePattern":
          const newSelectedTirePattern = e.target.value;
          setSelectedTirePattern(newSelectedTirePattern);

          // Find the corresponding tire configuration based on selected options
          const matchingTireConfig = tireConfigurationMaster.find(
            (config) =>
              config.tireMakeId.tireMakeId === parseInt(selectedTireMake) &&
              config.tireSize === selectedTireSize &&
              config.tirePattern === newSelectedTirePattern
          );

          if (matchingTireConfig) {
            const matchedTireThickness = matchingTireConfig.tireThickness;

            // Update the tire thickness value for the specific row
            const updatedTireThicknessValues = [...tireThicknessValues];
            updatedTireThicknessValues[i] = matchedTireThickness;
            setTireThicknessValues(updatedTireThicknessValues);

            // Update the otdMm value for the specific row
            const updatedInspectionDetails = [...inspectionDetails];
            updatedInspectionDetails[i].otdMm = matchedTireThickness;
            dispatch({
              type: SET_INSPECTION_DETAILS,
              payload: updatedInspectionDetails,
            });
          }
          break;

        default:
          break;
      }
    }

    dispatch({
      type: SET_INSPECTION_DETAILS,
      payload: inspectionDetails,
    });

    console.log("dispatch", inspectionDetails);
  };

  onsubmit = (type) => {
    const filterdata = inspectionDetails.filter(
      (i) =>
        (i.tirePosition &&
          i.tireType &&
          i.tireTypeLabel &&
          i.tireMake &&
          i.tireMakeLabel &&
          i.leastTireThicknessAllowed &&
          i.tireSize &&
          i.tirePattern &&
          i.recoPressure &&
          i.obsPressure &&
          i.otdMm &&
          i.lstMm &&
          i.ctMm &&
          i.rstMm &&
          i.observationCategory &&
          i.observationCategoryLabel &&
          i.observation &&
          i.observationLabel &&
          i.recommendationLabel) ||
        i.odometerReadingWhenRemoved ||
        i.tireOriginalFitmentDate ||
        i.odometerReadingWhenFitted ||
        i.tireSerialNo ||
        i.tireImageUrl
    );

    inspectionMethods.saveVehicleInspectionDetails(
      filterdata,
      type,
      parameters.inspectionId
    );

    console.log("filterData", filterdata);
  };

  const onFileSelect = async (e, i) => {
    let formData = new FormData();

    formData.append("file", e.target.files[0]);
    formData.append("key", "inspection-tires");
    formData.append("uniqueKey", parameters.inspectionId);

    const response = await inspectionMethods.uploadFile(formData);
    inspectionDetails[i].tireImageUrl = response;
    dispatch({
      type: SET_INSPECTION_DETAILS,
      payload: inspectionDetails,
    });
  };

  const removeImage = (i) => {
    inspectionDetails[i].tireImageUrl = "";
    dispatch({
      type: SET_INSPECTION_DETAILS,
      payload: inspectionDetails,
    });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Form>
              <div className="table-responsive form-group">
                <table className="table-bordered inspectionTableForm">
                  <thead>
                    <tr>
                      <th>Tire Position</th>
                      <th>New / Retreated</th>
                      <th>Tire Make</th>
                      <th>Tire Size</th>
                      <th>Tire Pattern</th>
                      <th>Tire Sr. No</th>
                      <th>OTD mm</th>
                      <th>RST mm</th>
                      <th>CT mm</th>
                      <th>LST mm</th>
                      <th>Reco. Pressure</th>
                      <th>Obs. Pressure</th>
                      <th>All Ok</th>
                      <th>Observation Category</th>
                      <th>Observation</th>
                      <th>Recommendation</th>
                      <th>Original Fitment Date</th>
                      <th>Odometer Reading when fitted</th>
                      <th>Least Tire Thickness Allowed mm</th>
                      <th>Tire Removal Date</th>
                      <th>Odometer Reading when removed</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-nowrap">
                    {inspectionDetails.map((item, i) => (
                      <tr scope="row" key={i}>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 0
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(0);
                          }}
                        >
                          <input
                            type="text"
                            className="form-control table-input"
                            // className={
                            //   selectedRowIndex === i && selectedCellIndex === 0
                            //     ? "selected-cell"
                            //     : ""
                            // }
                            placeholder="Tire Position"
                            value={item.tirePosition}
                            name="tirePosition"
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 1
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(1);
                          }}
                        >
                          <select
                            className="form-control table-input"
                            value={item.tireType}
                            name="tireType"
                            onChange={(e) =>
                              handleChange(e, null, null, i, "tireTypeLabel")
                            }
                          >
                            <option value={""}>select</option>
                            {tyreTypes.map((tire, index) => (
                              <option key={tire.id} value={tire.id}>
                                {tire.label}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 2
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(2);
                          }}
                        >
                          <select
                            className="form-control table-input"
                            defaultValue="Apollo"
                            value={item.tireMake}
                            name="tireMake"
                            onChange={(e) => {
                              handleTireMakeChange(e);
                              handleChange(e, null, null, i, "tireMakeLabel");
                            }}
                          >
                            <option value={""}>select</option>
                            {tireMake.map((tire, index) => (
                              <option
                                key={tire.tireMakeId}
                                value={tire.tireMakeId}
                              >
                                {tire.tireMakeName}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 3
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(3);
                          }}
                        >
                          <select
                            className="form-control table-input"
                            defaultValue=""
                            value={item.tireSize}
                            name="tireSize"
                            onChange={(e) => {
                              handleTireSizeChange(e);
                              handleChange(e, null, null, i);
                            }}
                          >
                            <option value={""}>select</option>
                            {tireData[item.tireMake] &&
                              tireData[item.tireMake].tireSize &&
                              tireData[item.tireMake].tireSize.map(
                                (tire, index) => (
                                  <option key={tire} value={tire}>
                                    {tire}
                                  </option>
                                )
                              )}
                          </select>
                        </td>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 4
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(4);
                          }}
                        >
                          <select
                            className="form-control table-input"
                            defaultValue=""
                            value={item.tirePattern}
                            name="tirePattern"
                            onChange={(e) => {
                              handleTirePatternChange(e, i);
                              handleChange(e, null, null, i, "tirePattern");
                            }}
                          >
                            <option value={""}>select</option>
                            {tireData[item.tireMake] &&
                              tireData[item.tireMake][item.tireSize] &&
                              tireData[item.tireMake][item.tireSize]
                                .tirePattern &&
                              tireData[item.tireMake][
                                item.tireSize
                              ].tirePattern.map((tire, index) => (
                                <option key={tire} value={tire}>
                                  {tire}
                                </option>
                              ))}
                          </select>
                        </td>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 5
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(5);
                          }}
                        >
                          <input
                            type="text"
                            className="form-control table-input"
                            placeholder="Tire Sr.No"
                            value={item.tireSerialNo}
                            name="tireSerialNo"
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td>
                        {/* <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 6
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(6);
                          }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="OTD mm"
                            value={item.otdMm}
                            name="otdMm"
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td> */}
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 6
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(6);
                          }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="OTD mm"
                            value={
                              selectedTirePattern !== "" &&
                              tireThicknessValues[i] !== undefined
                                ? tireThicknessValues[i]
                                : item.otdMm || ""
                            }
                            name="otdMm"
                            onChange={(e) => {
                              handleChange(e, null, null, i);
                            }}
                          />
                        </td>

                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 7
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(7);
                          }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="RST mm"
                            value={item.rstMm}
                            name="rstMm"
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 8
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(8);
                          }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="CT mm"
                            value={item.ctMm}
                            name="ctMm"
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 9
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(9);
                          }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="LST mm"
                            value={item.lstMm}
                            name="lstMm"
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 10
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(10);
                          }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="Reco.Pressure"
                            value={item.recoPressure}
                            name="recoPressure"
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 11
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(11);
                          }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="Obs. Pressure"
                            value={item.obsPressure}
                            name="obsPressure"
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td>
                        <td>
                          <label
                            style={{ marginBottom: "-1rem" }}
                            className="checkbox-container"
                          >
                            <input
                              style={{ marginRight: "1rem" }}
                              type="checkbox"
                              className="form-control table-input"
                              placeholder="All ok"
                              name="allOk"
                              checked={item.allOk}
                              onChange={(e) => handleAllOkChange(e, i)}
                            />
                          </label>
                          All OK
                        </td>
                        <td>
                          {selectedAllOkRows.includes(i) ? (
                            <select
                              className="form-control table-input"
                              value={"ALLOK"}
                              name="observationCategory"
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  null,
                                  null,
                                  i,
                                  "observationCategoryLabel"
                                )
                              }
                            >
                              <option value={"ALLOK"}>ALLOK</option>
                            </select>
                          ) : (
                            <select
                              className="form-control table-input"
                              value={item.observationCategory}
                              name="observationCategory"
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  null,
                                  null,
                                  i,
                                  "observationCategoryLabel"
                                )
                              }
                            >
                              <option value={""}>select</option>
                              {observationCategoryTypeOption?.map(
                                (data, index) => (
                                  <option
                                    key={data.observationCategoryId}
                                    value={data.observationCategoryId}
                                    data-dataid={data.observationCategoryId}
                                  >
                                    {data.observationCategoryType}
                                  </option>
                                )
                              )}
                            </select>
                          )}
                        </td>

                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 13
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(13);
                          }}
                        >
                          {selectedAllOkRows.includes(i) ? (
                            <select
                              name="observation"
                              className="form-control table-input"
                              value={"ALLOK"}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  null,
                                  null,
                                  i,
                                  "observationLabel"
                                )
                              }
                            >
                              <option value={"ALLOK"}>ALLOK</option>
                              {/* {observations
                                ?.filter(
                                  (data) =>
                                    data.observationCategoryType ===
                                    item.observationCategoryLabel // Filter observations based on selected observationCategoryLabel
                                )
                                .map((data, index) => (
                                  <option
                                    key={data.observationId}
                                    value={data.observationId}
                                    data-dataid={data.observationId}
                                  >
                                    {data.observationName}
                                  </option>
                                ))} */}
                            </select>
                          ) : (
                            <select
                              name="observation"
                              className="form-control table-input"
                              value={item.observation}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  null,
                                  null,
                                  i,
                                  "observationLabel"
                                )
                              }
                              disabled={
                                item.observationCategoryLabel.toLowerCase() ===
                                "normal"
                              }
                            >
                              <option value={""}>select</option>
                              {observations
                                ?.filter(
                                  (data) =>
                                    data.observationCategoryType ===
                                    item.observationCategoryLabel // Filter observations based on selected observationCategoryLabel
                                )
                                .map((data, index) => (
                                  <option
                                    key={data.observationId}
                                    value={data.observationId}
                                    data-dataid={data.observationId}
                                  >
                                    {data.observationName}
                                  </option>
                                ))}
                            </select>
                          )}
                        </td>

                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 14
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(14);
                          }}
                        >
                          {selectedAllOkRows.includes(i) ? (
                            <p>AllOK</p>
                          ) : (
                            <p>{item.recommendationLabel}</p>
                          )}
                        </td>

                        {/* <td>
                          <input
                            type="date"
                            className="form-control table-input"
                            placeholder="Original Fitment Date"
                            value={moment(item.tireOriginalFitmentDate).format(
                              "YYYY-MM-DD"
                            )}
                            name="tireOriginalFitmentDate"
                            onChange={(e) => handleChange(e, i)}
                          />
                        </td> */}
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 15
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(15);
                          }}
                        >
                          {item.tireOriginalFitmentDate
                            ? dayjs(item.tireOriginalFitmentDate).format(
                                "DD-MM-YYYY"
                              )
                            : ""}{" "}
                          <DatePicker
                            format={dateFormatList}
                            style={{ width: "12rem" }}
                            // value={
                            //   item.tireOriginalFitmentDate
                            //     ? dayjs(item.tireOriginalFitmentDate).format(
                            //         "DD-MM-YYYY"
                            //       )
                            //     : null
                            // }
                            onChange={(date, dateString) =>
                              handleChange(
                                null,
                                date,
                                dateString,
                                i,
                                "tireOriginalFitmentDate"
                              )
                            }
                          />
                        </td>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 16
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(16);
                          }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="Odometer Reading when fitted"
                            value={item.odometerReadingWhenFitted}
                            name="odometerReadingWhenFitted"
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td>
                        {/* <td><input type="number" className="form-control table-input" placeholder="OTD Projected Mileage (Kms)" value={item.otdProjectedMileage} name="otdProjectedMileage" onChange={(e) => handleChange(e, i)} /></td>                                                 */}
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 17
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(17);
                          }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="Least Tire Thickness Allowed mm"
                            defaultValue={3}
                            value={3}
                            name="leastTireThicknessAllowed"
                          />
                        </td>
                        {/* <td key={i}><input type="text" className="form-control table-input" placeholder="Tire Pattern" value={item.tirePattern} name="tirePattern" onChange={(e) => handleChange(e, i)} /></td> */}

                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 18
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(18);
                          }}
                        >
                          {item.tireRemovalDate
                            ? dayjs(item.tireRemovalDate).format("DD-MM-YYYY")
                            : ""}{" "}
                          <DatePicker
                            format={dateFormatList}
                            style={{ width: "12rem" }}
                            // value={
                            //   item.tireOriginalFitmentDate
                            //     ? dayjs(item.tireOriginalFitmentDate).format(
                            //         "DD-MM-YYYY"
                            //       )
                            //     : null
                            // }
                            onChange={(date, dateString) =>
                              handleChange(
                                null,
                                date,
                                dateString,
                                i,
                                "tireRemovalDate"
                              )
                            }
                          />
                        </td>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 19
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(19);
                          }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="Odometer Reading when removed"
                            value={item.odometerReadingWhenRemoved}
                            name="odometerReadingWhenRemoved"
                            onChange={(e) => handleChange(e, i)}
                          />
                        </td>

                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 20
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(20);
                          }}
                        >
                          {!item.tireImageUrl && (
                            <div className="upload-btn-wrapper">
                              <button className="btn-fill btn btn-info pointer">
                                Upload
                              </button>
                              <input
                                type="file"
                                name="myfile"
                                onChange={(e) => onFileSelect(e, i)}
                              />
                            </div>
                          )}
                          {item.tireImageUrl && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {item.tireImageUrl.split("/").pop()}{" "}
                              <i
                                className="fa fa-close"
                                onClick={(e) => removeImage(i)}
                                style={{
                                  marginTop: "5px",
                                  marginLeft: "8px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-center m-3">
                  <button
                    type="button"
                    className="btn-fill btn btn-info m-3"
                    onClick={() => onsubmit(2)}
                  >
                    Draft
                  </button>
                  <button
                    type="button"
                    className="btn-fill btn btn-info m-3"
                    onClick={() => onsubmit(1)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableForm;
