import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FETCH_ALL_CLIENT, SET_EDIT_CLIENT, SET_GST_REGISTRATION_OPTIONS } from "../redux/constants/ClientActionTypes";
import api from "../api";

const ClientMethods = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchAllClients = () => {
    return api.get("/masterservice/getallclientdata?page=1&size=200")
      .then(res => {
        dispatch({
          type: FETCH_ALL_CLIENT,
          payload: res.data.result
        });
      }).catch(err => {
        console.log(err);
      })
  }

  const storeClient = (data) => {
    return api.post("/masterservice/saveclient", data).then(res => {
      if (res.data.statusCode == 201) {
        toast.success("Client created Successful.");
        navigate('/clients');
        return;
      }
      toast.error(res.data.statusDescription);
      console.log(res.data.result);
    }).catch(err => console.log(err));
  }

  const fetchClient = (clientId = 1) => {
    return api.get(`/masterservice/getclientdata?clientId=` + clientId)
      .then(res => {
        dispatch({
          type: SET_EDIT_CLIENT,
          payload: res.data.result
        });
      }).catch(err => {
        console.log(err);
      })
  }

  const updateClient = (userId, client) => {
    const headers = {
      "Content-Type": "application/json"
    };
    return api.put(`/masterservice/updateclient?clientId=` + userId, client, { headers })
      .then(res => {
        if (res.data.statusCode == 200) {
          toast.success("Client updated Successful.");
          navigate("/clients");
          return;
        }
        toast.error(res.data.statusDescription);
      }).catch(err => {
        console.log(err);
        toast.error("Fetch Failed.");
      })
  }

  const clientStatusUpdate = ({ id, status }) => {
    const data = {
      "clientActiveStatus": status
    }
    const headers = {
      "Content-Type": "application/json"
    };
    return api.put(`/masterservice/enabledisableclient?clientId=` + id, data, { headers })
      .then(res => {
        toast.success("Status Updated Successful.");
        fetchAllClients();
      }).catch(err => {
        console.log(err);
      })
  }

  const fetchClientOptions = (datatype = 'gsttype') => {
    return api.get("/masterservice/masterdatalist?datatype="+datatype+"&parentId=0&activeStatus=1")
      .then(res => {
        console.log("res:",res)
        dispatch({
          type: SET_GST_REGISTRATION_OPTIONS,
          payload: res.data.result
        });
      }).catch(err => {
        console.log(err);
      })
  }

  return {
    fetchAllClients,
    storeClient,
    fetchClient,
    updateClient,
    clientStatusUpdate,
    fetchClientOptions
  };
}

export default ClientMethods