import { FETCH_ALL_INSPECTION_TYPE, SET_EDIT_INSPECTION_TYPE, SET_INSPECTION_DETAILS } from "../constants/InspectionTypes"

const initialState = {
    inspectionList: [],
    editInspection: {},
    inspectionDetails: []
}

export const InspectionReducer = (state = initialState, { type, payload }) => {
    if(type === SET_INSPECTION_DETAILS) {
        console.log("🚀 ~ file: InspectionReducer.js:10 ~ InspectionReducer ~ payload:", payload)
        
    }
    switch (type) {
        case FETCH_ALL_INSPECTION_TYPE:
            return {
                ...state,
                inspectionList: payload?.content
            }
        case SET_EDIT_INSPECTION_TYPE:
            return {
                ...state,
                editInspection: payload
            }
        case SET_INSPECTION_DETAILS:
            return {
                ...state,
                inspectionDetails: payload
            }     
        default:
            return state
    }
}